
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import KanbanView from '@/components/Sales/KanbanView.vue';
import Utils from "@/utility/utils";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";

// Types
import Customer from "@/types/customer";
import { RepItem } from "@/types/customer";
import SalesOrder from "@/types/salesorder";
import Pdf from "@/types/pdf";
import Filter, { FilterConstraints } from "@/types/filter";

// Services
import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();
const salesService = new SalesService();

export default defineComponent({
  name: "SalesDataWrapper",
  components: {
    DataTable,
    Button,
    InputText,
    Column,
    Calendar,
    LoadingSpinner,
    SalesDialog,
    KanbanView,
    MultiselectWithButton,
    EmailFileDialog,
  },
  created() {
    if (this.customerIndex !== undefined) {
      this.filters.cust_name.value = [this.getCurrentCustomer.name];
      this.selectedFilterCusts = [this.getCurrentCustomer];
      this.storeSortFilters();
    } else {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
    }

    this.applyStoredSortFilters();
    this.fetchOrdersData(false);
  },
  computed: {
    ...mapGetters({
      getOrders: "sales/getOrders",
      getPDFs: "sales/getPDFS",
      getLoadingPDFs: "sales/getLoadingPDFs",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getClient: "session/getClient",
      getSoIds: "customerInquiry/getSoIds",
      getSalesOrdersSortFilters: "filters/getSalesOrdersSortFilters",
    }),
  },
  beforeUpdate() {
    this.orderTotal();
  },
  props: {
    customerIndex: Number,
    currentView: String,
    isResultView: {
      type: Boolean,
      default: true,
    },
    maxExportRows: {
      type: Number,
      required: true
    },
    showSelectionCheckbox:{ type: Boolean, default: false },
  },
  emits: ['update-badge', 'lastUpdated'],
  data() {
    return {
      isProduction: this.isProduction,
      printExportItems: [] as Array<any>,
      items: [] as Array<any>,
      selectedSalesID: "",
      selectedStatus: "",
      dateStart: "",
      dateEnd: "",
      selectedSale: null,
      selectedRow: null,
      id: "",
      showEmailDialog: false,
      orderIdToEmail: "",
      fileName: "",
      showMail: false,
      showLoadExistingQuoteBtn: false,
      customerContacts: [] as Array<any>,
      selectedSalesStatus: null,
      readOnly: false,
      sortOrder: -1,
      sortField: "date",
      first: 0,
      rows: 10,
      page: 1,
      rangeStart: 1,
      rangeEnd: 10,
      totalRecords: 0,
      isLoadingSales: false,
      formatedLists: [] as Array<any>,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      statusLabel: {
        N: 'New',
        B: 'Back Order',
        C: 'Closed',
      },
      filters: {
        cust_name: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
        so_id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: [] as Array<string>,
          matchMode: FilterMatchMode.IN,
        },
        date: {
          value:  []as Array<Date>,
          matchMode: FilterMatchMode.IN,
        },
      },
      allExportColumns: [ 
        { field: 'so_id', header: 'ID', input: true },
        { field: 'status', header: 'Status', input: true },
        { field: 'cust_name', header: 'Customer', input: true },
        { field: 'date', header: 'Order Date', input: true },
        { field: 'po_number', header: 'Purchase', input: true },
        { field: 'order_total_sum', header: 'Order Total', input: true },
        { field: 'stamp_id', header: 'Created By', input: true },
        { field: 'type', header: 'Type', input: true },
        { field: 'rep_items', header: 'Reps', input: true}
      ],
      dynamicColumns: [
        { field: 'so_id', header: 'ID', input: true },
        { field: 'status', header: 'Status', input: true },
        { field: 'cust_name', header: 'Customer', input: true },
        { field: 'date', header: 'Order Date', input: true },    
        { field: 'po_number', header: 'Purchase', input: true },
        { field: 'order_total_sum', header: 'Order Total', input: true },
        { field: 'stamp_id', header: 'Created By', input: true },
        { field: 'type', header: 'Type', input: true },
        { field: 'rep_items', header: 'Reps', input: true}

      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      loadingSalesOrderPDFs: [] as Array<string>,
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      selectedFilterId: "",
      selectedFilterStatus: [] as any[],
      selectedFilterDate: undefined as any,
    };
  },
  watch: {
    getCurrentCustomer() {
      if (this.getCurrentCustTabIndex == this.customerIndex && this.getCurrentCustomer) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCust();
      }
    },
    items() {
      if (!this.isResultView){
        this.formatBoardList();
      }
    },
    isResultView() {
      this.clearAllFilters();
      
      if (!this.isResultView) {
        this.formatBoardList();
      }
      this.fetchOrdersData(false);
    }
  },
  mounted() {
    this.first = this.getFirstRow;
    this.applyStoredSortFilters();
  },
  unmounted() {
    this.clearAndResetOrders();
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      setFirstRow: "sales/setFirstRow",
      clearAndResetOrders: "sales/clearAndResetOrders",
      getOrderPDF: "sales/getOrderPDF",
      openPDF: "sales/openPDF",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      addNotification: "notification/add",
      setSalesOrderSortFilters: "filters/setSalesOrderSortFilters",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    formatRepItems(repItems: RepItem[]) {
      return repItems && repItems
        .filter(item => item.rep_name)
        .map(item => item.rep_name)
        .join(',\n');
    },
    applyStoredSortFilters() {
      const filters = this.getSalesOrdersSortFilters.filters;
      for (const filter in filters) {
        switch(filter) {
          case "so_id":
            this.selectedFilterId = filters[filter];
            break;
          case "status":
            this.selectedFilterStatus = filters[filter];
            break;
          case "date":
            this.selectedFilterDate = filters[filter];
            break;
          case "cust_name":
            this.selectedFilterCusts = filters[filter];
            this.handleCustomerSelectFilter(filters[filter])
            break;
        }
      }
      this.sortField = this.getSalesOrdersSortFilters.sort.sortField;
      this.sortOrder = this.getSalesOrdersSortFilters.sort.sortOrder;
    },
    storeSortFilters() {
      const filterCusts = {
        value: this.selectedFilterCusts
      }
      const sortFiltersToStore = Utils.getSortFiltersToStore({ filters: {...this.filters, cust_name: filterCusts }, sortField: this.sortField, sortOrder: this.sortOrder});
      this.setSalesOrderSortFilters(sortFiltersToStore);
    },
    showRow(event: any) {
      this.salesOrder = event;
      this.openSales(true);
      this.id = event.so_id;
    },
    rowClick(event: any) {
      // don't fire for selection checkbox
      if ( event.originalEvent && event.originalEvent.target.className.includes("p-checkbox-icon")) {
        return;
      }
      if(this.isProduction) {
        let itemToEdit = event;
        if (event.data) {
          itemToEdit = event.data;
        }
        this.showRow(itemToEdit);
      } else {
        event.data['saleType'] = 'orders';
        this.addOpenedSalesOrder(event.data)
        this.$router.push(`/sales/orders/${event.data.so_id}`)
      }
    },
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
      this.showLoadExistingQuoteBtn = false;
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.getOrders.length == this.getRangeEnd - 100
      ) {
        this.fetchOrdersData(true);
      }
    },
    orderTotal() {
      let sumValue = 0;
      let totalValue = 0;
      let itemsLength = this.items && this.items.length ? this.items.length : 0
      for (
        let objectIndex = 0;
        objectIndex < itemsLength;
        objectIndex++
      ) {
        for (
          let innerObjectIndex = 0;
          innerObjectIndex < this.items[objectIndex].lis_items.length;
          innerObjectIndex++
        ) {
          if (
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_qtys
              )
            ) &&
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex].li_prices
              )
            )
          ) {
            //if both price and qty are of type NaN, assign current li_order_total to blank string
            this.items[objectIndex].lis_items[innerObjectIndex].li_order_total =
              "";
          } else if (
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_qtys
              )
            ) == false &&
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex].li_prices
              )
            )
          ) {
            //if quantity is not of type NaN, convert qty value to string, assign qty to li_order_total
            this.items[objectIndex].lis_items[innerObjectIndex].li_order_total =
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_qtys
              ).toString();
            totalValue =
              totalValue +
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_total
              );
          } else if (
            //if price is not of type NaN, convert price value to string, assign price to li_order_total
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_qtys
              )
            ) &&
            isNaN(
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex].li_prices
              )
            ) == false
          ) {
            this.items[objectIndex].lis_items[innerObjectIndex].li_order_total =
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex].li_prices
              ).toString();
            totalValue =
              totalValue +
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_total
              );
          } else {
            //calculates order sum total if both qty and price are not of NaN type
            sumValue =
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_qtys
              ) *
              parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex].li_prices
              );
            this.items[objectIndex].lis_items[innerObjectIndex].li_order_total =
              sumValue.toString();
            if (this.items[objectIndex].lis_items.length > 1) {
              //add all lis_items if more than 1 item exists
              totalValue =
                totalValue +
                parseFloat(
                  this.items[objectIndex].lis_items[innerObjectIndex]
                    .li_order_total
                );
            } else {
              totalValue = parseFloat(
                this.items[objectIndex].lis_items[innerObjectIndex]
                  .li_order_total
              );
            }
          }
        }
        this.items[objectIndex].order_total_sum = totalValue
          .toFixed(2)
          .toString();
        totalValue = 0;
      }
    },
    sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;

      this.fetchOrdersData(false);
    },
    handleCustomerSelectFilter: _debounce(async function(event) {
      const customers = await customerService.getAllCustomers({
        selection: event.value
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([...customers.cust_items.sort((a, b) => a.name.localeCompare(b.name)), ...this.selectedFilterCusts]);
    }, 1000),
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    fetchOrdersData(addOrder: boolean) {
      let sortOrderRecordsBy = '';
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      let sortBy = this.sortField.replaceAll("_", ".");

      if (this.sortOrder === -1) {
        sortOrderRecordsBy = "DEC";
      }

      // Filter logic
      let saleIds = ""
      if (this.currentView === 'customers') {
        if (this.selectedFilterId) {
          if (!this.getSoIds(this.customerIndex).includes(this.selectedFilterId)) {
            this.isLoadingSales = false;
            return;
          }
        } else {
          saleIds = this.getSoIds(this.customerIndex).join(" ");

          if(!saleIds) {
            return;
          }
        }
      }

      this.isLoadingSales = true;
      this.filters.so_id.value = this.selectedFilterId;
      this.filters.status.value = [...this.selectedFilterStatus];
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });

      this.filters.date.value = [];
      if (this.selectedFilterDate) {
        if (this.selectedFilterDate[0] != null) this.dateStart = this.selectedFilterDate[0];
        if (this.selectedFilterDate[1] != null) this.dateEnd = this.selectedFilterDate[1];

        this.dateStart = this.formatStringDate(this.dateStart);
        this.dateEnd = this.formatStringDate(this.dateEnd);

        if (this.dateStart) this.filters.date.value.push(new Date(this.selectedFilterDate[0]));
        if (this.dateEnd) this.filters.date.value.push(new Date(this.selectedFilterDate[1]));
      }
      this.storeSortFilters();
      this.fetchOrdersRecords({
        ids:  saleIds || this.filters.so_id.value,
        status: this.selectedFilterStatus,
        custs: this.getCustomersFilter(),
        sortBy: sortBy,
        sortOrder: sortOrderRecordsBy,
        addOrder: addOrder,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd || this.dateStart,
        correls: "cust_name rep_name bill_to_name hold_code_desc order_amount cust_resale currency_code exchange_rate pending_ship li_qty_bal li_sch_qty_bal"
      })
        .then((resp) => {
          this.$emit('lastUpdated', Date.now());
          this.items = resp.data;
          this.orderTotal();
            this.printExportItems = this.items.map((item: SalesOrder) => {
              let repItems = this.formatRepItems(item.rep_items as any[]);
              return {
                so_id: item.so_id,
                status: Utils.formatStatus(item.status),
                cust_name: item.cust_name,
                date: this.formatStringDate(item.date),
                po_number: item.po_number,
                stamp_id: item.stamp_id,
                type: item.type,
                rep_items: repItems,
                order_total_sum: item.order_total_sum,
              }
            });
        this.totalRecords = resp.data.length;
        })
        .catch(() => {
          this.isLoadingSales = false;
        }).finally(() => {
          this.isLoadingSales = false;
        });
      if (!addOrder) {
        this.page = 1;
        this.first = 0;
      }
    },
    async printTable() {
    //block a print with no criteria applied
      if(this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
          const notification = {
              message: `Please filter the results before printing.`,
              type: "error",
          };
          this.addNotification(notification);
      }
      else {
          this.clearPrintData();
          // get full dataset for the current criteria
          await this.fetchOrdersData(false);
          this.setPrintData(this.printExportItems);
          this.setPrintDefaultColumns(this.dynamicColumns);
          this.setPrintAvaialbleColumns(this.allExportColumns);

          window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
    clearAllFilters() {
      if (this.customerIndex !== undefined) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.storeSortFilters();
      } else {
        this.filters.cust_name.value = [];
        this.selectedFilterCusts = [];
      }
      this.selectedFilterId = "";
      this.filters.so_id.value = "";
      this.selectedFilterStatus = [];
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate= null;
      this.filters.date.value = [];
    },
    clearAllFiltersAndFetch() {
      this.clearAllFilters();
      this.fetchOrdersData(false);
    },
    clearSalesSearchBar() {
      this.selectedFilterId = "";
      this.filters.so_id.value = "";
      this.fetchOrdersData(false) 
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      //(((this.filters as SalesOrder)['status'] as unknown) as FilterConstraints).value = null;
      this.fetchOrdersData(false);
    },

    clearDateFilter(){
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate= null;
      this.filters.date.value = [];
      this.fetchOrdersData(false);
    },

    clearCustomerSearch() {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      this.fetchOrdersData(false);
    },

    filterCust() {
      this.isLoadingSales = true;
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      })
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.fetchOrdersData(false);
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate
    },
    formatCurrency: (value: string) => {
      return (+value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    getSalesOrderPDF(id: string) {
      this.getOrderPDF({client: this.getClient, recordId: id});
    },
    salePDFIcon(id: string) {
      let downloaded: Array<Pdf> = this.getPDFs
      let downloading: boolean = this.getLoadingPDFs.find((i: string) => i === id) !== undefined
      return {
        "pi pi-download":
          downloaded.find((i: Pdf) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: Pdf) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    handleFilter(event: any) {
      this.$emit('update-badge', 'so_ids', event.filteredValue.length)
    },
    formatBoardList() {
      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(JSON.stringify(this.status));
      controlStatusList.unshift({
        status: 'Unassigned',
      });
      controlStatusList.forEach(element => element.items = []);

      this.items.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(element => element.initial === item.status);
        if (statusIndex >= 0) {
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      })
      this.formatedLists = controlStatusList;
    },
    onStatusChange(event: any) {
      let quoteWithNewStatus = JSON.parse(JSON.stringify(event.item.added.element));
      quoteWithNewStatus.status = event.status;
      salesService.putOrder(quoteWithNewStatus.so_id, quoteWithNewStatus);
    },
    async handleEmailPDF(salesOrder: SalesOrder) {
      const orderId = salesOrder.so_id;
      this.orderIdToEmail = orderId;
      
      await customerService.getCustomer(salesOrder.sold_to, "", "contact_name contact_email").then((response: any) => {
        const customer = response as Customer;
        this.customerContacts = customer.contact_id_items;
      }).catch((error) => {
        this.addNotification({
          message: `Customer contacts could not load: ${error}`,
          type: "error",
        });
      }).finally(() => {
        this.showEmailDialog = true;
      });
    },
    sendEmail(data: any) {
      salesService.getOrderPDF(this.orderIdToEmail, this.getClient, data)
      .then((response) => {
          if(response === 'success') {
            this.addNotification({
              message: `Sales Order has been emailed successfully`,
              type: "success",
            });
          }
          else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        }).catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        })
    }
  },
});
