
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import SalesDataWrapper from "@/components/Sales/SalesDataWrapper.vue";
import NotificationCard from "@/components/Notification-Card.vue";
import SalesHeaderButtons from "@/components/Sales/SalesHeaderButtons.vue";

export default defineComponent({
  name: "Sales",
  components: {
    SalesDataWrapper,
    SalesDialog,
    NotificationCard,
    Card,
    SalesHeaderButtons,
  },
  computed: {
    ...mapState(["shipControl", "salesInquiry"]),
    ...mapGetters({
      getOrders: "sales/getOrders",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
      getNewOrdersCount: "salesInquiry/getNewOrdersCount",
    }),
  },
  created() {
    this.getShipControl();
  },
  data() {
    return {
      isProduction: this.isProduction,
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
    };
  },
  mounted() {
    this.first = this.getFirstRow;
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      setFirstRow: "sales/setFirstRow",
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      getShipControl: "shipControl/getShipControl",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
    }),
    printExportData() {
      (this.$refs as any).salesOrderTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOrderTable.clearAllFiltersAndFetch();
    },
    openSales(edit: boolean) {
      this.readOnly = edit;
      if (this.isProduction) {
        this.showSalesDialog = true;
      } else {
        const count =
          this.getNewOrdersCount === undefined ? 1 : this.getNewOrdersCount + 1;
        const itemToEdit = {
          so_id: `New Order-${count}`,
          saleType: "orders",
          oldRecord: {},
        };
        this.addOpenedSalesOrder(itemToEdit);
        this.$router.push(`/sales/orders/New%20Order-${count}`);
      }
    },
    fetchFilteredOrders(addOrder: boolean) {
      this.isLoadingSales = true;
      this.fetchOrdersRecords({
        custs: [this.selectedCustomer?.["cust_id"]],
        id: this.selectedSalesID,
        status: this.selectedSalesStatus,
        addOrder: addOrder,
        correls: "cust_name",
      })
        .then((resp) => {
          this.items = resp.data;
        })
        .catch(() => {
          this.isLoadingSales = false;
        }).finally(() => {
          this.isLoadingSales = false;
        });
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
